import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { PricingCard, useCheckoutContext } from 'modules/PricingPage';
import { formatPrice, getExpiryLabel, triggerGtagEvent } from 'services';
import { useDevice, useAuthenticationUser } from 'services/hooks';

import {
  PriceHeading, Column, Row, ExtendedButton, PriceText, ExtendedHeading, ValidToText, PerClassText,
} from './styled';

export const ClassCard = ({ item }: ClassCardProps) => {
  const { isMobile } = useDevice();
  const { navigate, originalPath } = useI18next();
  const { t } = useTranslation();
  const { handleOpenModal } = useCheckoutContext();
  const { authenticated } = useAuthenticationUser();
  const price = item.groupPrice?.amount || item.price.amount;
  const pricePerClass = formatPrice(price / item.classCount, { hideZeroDecimals: true });
  const expiryLabel = getExpiryLabel(item.expirationInterval, item.expiresIn);
  const name = item.name.split('[')[0];

  const handleModal = (item: i.Serie) => {
    if (!authenticated) {
      return navigate('/register',
        {
          state: {
            redirectUrl: originalPath,
          },
        });
    }

    // JSON Template for GTAG
    // Google default payload, unused fields disabled
    const eventPayload = {
      currency: item.price.currency,
      value: item.price.amount,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          // affiliation: 'affiliation',
          // coupon: 'coupon',
          // discount: 'discount',
          index: 0,
          // item_brand: 'item_brand',
          item_category: item.seriesType,
          // item_list_id: 'item_list_id',
          // item_list_name: 'item_list_name',
          // item_variant: 'item_variant',
          // location_id: 'location_id',
          price: item.price,
          quantity: 1,
        },
      ],
    };
    triggerGtagEvent('add_to_cart', eventPayload);
    handleOpenModal(item);
  };

  return (
    <PricingCard name={item.name}>
      <Row gap="14px">
        <ExtendedHeading
          upperCase
          variant="black"
          margin="0"
          as="h2"
        >
          {name}
        </ExtendedHeading>
        <Row margin="0 18px">
          <PriceHeading
            variant="black"
            as="h3"
            margin="0 8px"
          >
            {pricePerClass}
          </PriceHeading>
          <PerClassText>{t('per class')}</PerClassText>
        </Row>
      </Row>
      <Row gap="16px">
        <Column>
          <PriceText>{formatPrice(price)} {t('in total')}</PriceText>
          {isMobile && (
            <ValidToText>{t('valid for')} {expiryLabel}</ValidToText>
          )}
        </Column>
        <ExtendedButton
          variant="CTA"
          onClick={() => handleModal(item)}
        >
          {t('buy now')}
        </ExtendedButton>
        {!isMobile && (
          <ValidToText>{t('valid for')} {expiryLabel}</ValidToText>
        )}
      </Row>
    </PricingCard>
  );
};

type ClassCardProps = {
  item: i.Serie;
};
